import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel2';
// import 'react-owl-carousel2/lib/styles.css';
// import 'react-owl-carousel2/lib/owl.theme.default.css';

class TeamOne extends Component {
    state = {
        data: null,
        error: null,
        loading: true,
    };

    componentDidMount() {
        fetch('clientsdata/logos.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch logos data');
                }
                return response.json();
            })
            .then(data => {
                this.setState({ data, loading: false });
            })
            .catch(error => {
                console.error('Error fetching logos data:', error);
                this.setState({ error, loading: false });
            });
    }

    render() {
        const { data, error, loading } = this.state;

        const options = {
            loop: true,
            margin: 20,
            nav: false,
            dots: false,
            smartSpeed: 2000,
            autoplay: true,
            autoplayTimeout: 4000,
            responsive: {
            0: {
                items: 2,
            },
            576: {
                items: 3,
            },
            768: {
                items: 4,
            },
            992: {
                items: 5,
            },
            },
        };

        if (loading) {
            return <div>Loading...</div>;
        }

        if (error) {
            return <div>Error: {error.message}</div>;
        }

        return (
            <section id="team" className="section team-area">
                <div className="container">
                    <div className="row">
                        <OwlCarousel options={options}>
                            {data && data.logoDatas && data.logoDatas.map((item, idx) => (
                                <div key={`to_${idx}`} className="single-team text-center px-3 py-2 m-2">
                                    <img loading="lazy" src={item.image} alt={item.alt} width={222} height={80} />
                                </div>
                            ))}
                        </OwlCarousel>
                    </div>
                </div>
            </section>
        );
    }
}

export default TeamOne;
